<template>
  <main>
    <div class="navigation-button">
      <Hamburger
        v-on:click-hamburger="handleClickHamburger"
        :toggle.sync="this.mobile_menu_active"
      />
    </div>
    <nav class="navigation" :class="this.mobile_menu_active ? 'mobile' : ''">
      <a class="navigation-title" href="#">
        <img src="@/assets/img/logo.svg" alt="Vitality | Affitti Brevi" />
      </a>
      <div class="navigation-menu">
        <div
          v-on:click="menuClick('header', { behavior: 'smooth' })"
          class="navigation-item"
          :class="{ active: menu_active == 'header' }"
        >
          {{ $t("menu[0]") }}
        </div>
        <div
          v-on:click="menuClick('section-appartamento', { behavior: 'smooth' })"
          class="navigation-item"
          :class="{ active: menu_active == 'section-appartamento' }"
        >
          {{ $t("menu[1]") }}
        </div>
        <div
          v-on:click="menuClick('section-contacts', { behavior: 'smooth' })"
          class="navigation-item"
          :class="{ active: menu_active == 'section-contacts' }"
        >
          {{ $t("menu[2]") }}
        </div>
        <div class="navigation-item">
          <language-switcher
            :mobile="this.mobile_menu_active ? 'mobile' : ''"
          />
        </div>
        <div class="navigation-item">
          <a
            href="https://www.facebook.com/vitalityaffittibrevi"
            target="_blank"
            style="color: #1b74e4"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
        </div>
      </div>
    </nav>
    <header class="header">
      <div class="header-text-box">
        <div class="header-title">
          <h1 class="text-900">{{ $t("header.titolo") }}</h1>
          <h2 class="text-primary text-900">{{ $t("header.sotto_titolo") }}</h2>
        </div>
        <div class="header-button">
          <button
            class="button"
            v-on:click="
              menuClick('section-appartamento', { behavior: 'smooth' })
            "
          >
            {{ $t("header.bottone") }}
          </button>
        </div>
      </div>
    </header>
    <section class="section-about">
      <div class="container">
        <div class="row">
          <div class="column">
            <p><span v-html="$t('home.presentazione')"></span></p>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="main-image">
              <img :src="mainImageSrc" />
              <div class="descr_img">{{ mainImageDescr }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-appartamento">
      <div class="container">
        <div class="row">
          <div class="column">
            <p><span v-html="$t('home.presentazione_appartamento')"></span></p>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <img src="@/assets/img/sala.jpg" alt="Sala" />
          </div>
          <div class="column">
            <img src="@/assets/img/bagno.jpg" alt="Bagno" />
          </div>
        </div>
      </div>
    </section>
    <section class="section-dotazioni">
      <div class="container">
        <div class="row">
          <div class="column text-center">
            <h2 class="text-primary text-900">
              {{ $t("home.dotazioni_title") }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="column dotazioni-column text-center">
            <img
              class="svg-sm"
              src="@/assets/img/lavatrice.svg"
              alt="Lavatrice"
            />
            <br />
            <span v-html="$t('home.dotazioni[0]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img class="svg-sm" src="@/assets/img/stiro.svg" alt="Stiro" />
            <br />
            <span v-html="$t('home.dotazioni[1]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img
              class="svg-sm"
              src="@/assets/img/asciugamani.svg"
              alt="Biancheria"
            />
            <br />
            <span v-html="$t('home.dotazioni[2]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img class="svg-sm" src="@/assets/img/tv.svg" alt="TV" />
            <br />
            <span v-html="$t('home.dotazioni[3]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img class="svg-sm" src="@/assets/img/wifi.svg" alt="WiFi" />
            <br />
            <span v-html="$t('home.dotazioni[4]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img class="svg-sm" src="@/assets/img/animali.svg" alt="Animali" />
            <br />
            <span v-html="$t('home.dotazioni[5]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img class="svg-sm" src="@/assets/img/bici.svg" alt="Bici" />
            <br />
            <span v-html="$t('home.dotazioni[6]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img class="svg-sm" src="@/assets/img/zanzariera.svg" alt="Zanzariera" />
            <br />
            <span v-html="$t('home.dotazioni[7]')"></span>
          </div>
          <div class="column dotazioni-column text-center">
            <img class="svg-sm" src="@/assets/img/clima.svg" alt="Climatizzatore" />
            <br />
            <span v-html="$t('home.dotazioni[8]')"></span>
          </div>
        </div>
      </div>
    </section>
    <section class="section-profiles">
      <div class="container">
        <div class="row profile-esclusivo">
          <div class="column">
            <div class="row">
              <div class="column">
                <h1 class="text-800 text-black">
                  {{ $t("home.esclusivo.titolo_1") }}
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="column column-75 column-offset-25">
                <h1 class="text-800 text-primary">
                  {{ $t("home.esclusivo.titolo_2") }}
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="column">
                <p><span v-html="$t('home.esclusivo.descr')"></span></p>
              </div>
            </div>
          </div>
          <div class="column">
            <img src="@/assets/img/cucina.jpg" alt="Cucina" />
          </div>
        </div>

        <div class="row profile-matrimoniale">
          <div class="column">
            <img
              src="@/assets/img/matrimoniale.jpg"
              alt="Stanza matrimoniale"
            />
          </div>
          <div class="column">
            <div class="row">
              <div class="column">
                <h1 class="text-800 text-black">
                  {{ $t("home.matrimoniale.titolo_1") }}
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="column column-75 column-offset-25">
                <h1 class="text-800 text-primary">
                  {{ $t("home.matrimoniale.titolo_2") }}
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="column">
                <p><span v-html="$t('home.matrimoniale.descr')"></span></p>
              </div>
            </div>
          </div>
        </div>

        <div class="row profile-doppia">
          <div class="column">
            <div class="row">
              <div class="column">
                <h1 class="text-800 text-black">
                  {{ $t("home.doppia.titolo_1") }}
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="column column-75 column-offset-25">
                <h1 class="text-800 text-primary">
                  {{ $t("home.doppia.titolo_2") }}
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="column">
                <p><span v-html="$t('home.doppia.descr')"></span></p>
              </div>
            </div>
          </div>
          <div class="column">
            <img src="@/assets/img/doppia.jpg" alt="Camera doppia" />
          </div>
        </div>
      </div>
    </section>
    <section class="section-city">
      <div class="container">
        <div class="row">
          <div class="column text-center">
            <h2 class="text-primary text-900">
              {{ $t("home.punti_interesse") }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <table>
              <tr>
                <th>{{ $t("home.luogo") }}</th>
                <th>{{ $t("home.distanza") }}</th>
              </tr>
              <tr v-for="(punto, index) in punti_interese" :key="index">
                <td>{{ punto.luogo }}</td>
                <td>{{ punto.distanza }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section id="contatti" class="section-contacts">
      <div class="container">
        <div class="row">
          <div class="column text-center">
            <h2 class="text-primary text-900">
              {{ $t("home.contatti") }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.6975268104543!2d11.61965457559575!3d45.516167212497294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f2e4143773943%3A0x4ba0a373242b8b9e!2sVia%20Europa%2C%2028%2C%2036040%20Torri%20di%20Quartesolo%20VI!5e0!3m2!1sit!2sit!4v1638862180216!5m2!1sit!2sit"
              width="100%"
              height="600"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div class="row" style="margin-top: 10rem">
          <div class="column">
            <form @submit.prevent="handleSubmit">
              <fieldset>
                <label for="nameField">{{ $t("home.form.nome") }}</label>
                <input
                  name="nameField"
                  type="text"
                  placeholder=""
                  id="nameField"
                  v-model="form_email.nameField"
                />
                <label for="emailField">{{ $t("home.form.email") }}</label>
                <input
                  name="emailField"
                  type="email"
                  placeholder=""
                  id="emailField"
                  v-model="form_email.emailField"
                />
                <label for="titleField">{{ $t("home.form.oggetto") }}</label>
                <input
                  name="titleField"
                  type="text"
                  placeholder=""
                  id="titleField"
                  v-model="form_email.titleField"
                />
                <label for="messageField">{{
                  $t("home.form.messaggio")
                }}</label>
                <textarea
                  name="messageField"
                  placeholder="Ciao ..."
                  id="messageField"
                  v-model="form_email.messageField"
                ></textarea>
              </fieldset>
              <div class="float-right">
                <input
                  name="confirmField"
                  type="checkbox"
                  id="confirmField"
                  v-model="form_email.confirmField"
                />
                <label class="label-inline" for="confirmField">{{
                  $t("home.form.invia_copia")
                }}</label>
              </div>
              <input
                class="button-primary"
                type="submit"
                :value="$t('home.form.invia')"
              />
            </form>
          </div>
          <div class="column text-center">
            <label>Indirizzo</label>
            <div
              itemprop="address"
              itemscope
              itemtype="http://schema.org/PostalAddress"
            >
              <span itemprop="streetAddress">Via Europa 28</span>,
              <span itemprop="postalCode">36040</span>
              <span itemprop="addressLocality">Torri di Quartesolo</span> VI
              <meta itemprop="addressRegion" content="Veneto" />
              <meta itemprop="addressCountry" content="Italia" />
            </div>
            <br />
            <label>Telefono</label>
            <div>
              <span itemprop="telephone">+39 3273182287</span> -
              <span itemprop="telephone">+39 3923025090</span>
            </div>
            <br />
            <label>Email</label>
            <div>
              <a href="mailto:info@vitalityaffitti.it"
                ><span itemprop="email">info@vitalityaffitti.it</span></a
              >
            </div>
            <br />
            <label>Codice di Regione</label>
            <div>024108-LOC-00011</div>
            <br />
            <label>Codice struttura</label>
            <div>I90374</div>
            <br />
            <a href="https://www.anbba.it/" target="blank">
              <img src="../assets/img/logo_anbba.png" alt="ANBBA" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div class="row">
        <div class="column text-center">
          © 2022 VITALITY | Affitti brevi. All rights reserved.
        </div>
      </div>
      <meta itemprop="url" content="https://www.vitalityaffitti.it/" />
      <meta itemprop="name" content="Vitality affitti brevi" />
    </footer>
  </main>
</template>

<script>
import LanguageSwitcher from "../components/LanguageSwitcher.vue";
import Hamburger from "../components/Hamburger.vue";

import colli from "@/assets/img/carousel/colli_new.jpg";
import rotonda from "@/assets/img/carousel/rotonda_new.jpg";
import san_bortolo from "@/assets/img/carousel/san_bortolo_new.jpg";
import teatro from "@/assets/img/carousel/teatro_new.jpg";
import basilica from "@/assets/img/carousel/basilica_new.jpg";
import villa_nani from "@/assets/img/carousel/villa_nani_new.jpg";
import fiera from "@/assets/img/carousel/fiera_vicenza_new.jpg";

export default {
  name: "Home",
  components: {
    LanguageSwitcher,
    Hamburger,
  },
  data() {
    return {
      mobile_menu_active: false,
      menu_active: undefined,
      punti_interese: [
        {
          luogo: "A4 TORINO-TRIESTE - VICENZA EST",
          distanza: "1,5Km",
        },
        { luogo: "Villa La Rotonda", distanza: "8,8Km" },
        {
          luogo: "A13 VICENZA-P.ROCCHETTE - VICENZA NORD",
          distanza: "9,5Km",
        },
        { luogo: "Villa Valmarana Ai Nani", distanza: "9,6Km" },
        { luogo: "Ospedale San Bortolo", distanza: "7,8Km" },
        { luogo: "Casa Di Cura Villa Berica", distanza: "8Km" },
        { luogo: 'Stadio "Romeo Menti"', distanza: "10,3Km" },
        {
          luogo: "Casa Di Cura Villa Margherita",
          distanza: "8,6Km",
        },
        {
          luogo: "Conservatorio Arrigo Pedrollo",
          distanza: "8,3Km",
        },
        { luogo: "Museo Del Risorgimento", distanza: "7,38Km" },
        { luogo: "Palazzo Barbarian Da Porto", distanza: "9,3Km" },
        { luogo: "Palazzo Chiericati", distanza: "11,2Km" },
        {
          luogo: "Museo Civico Di Palazzo Chiericati",
          distanza: "11,2Km",
        },
        { luogo: "Teatro Olimpico", distanza: "11,3Km" },
        
        { luogo: "Fiera di Vicenza", distanza: "10,4Km" }
      ],
      form_email: {
        nameField: "",
        emailField: "",
        titleField: "",
        messageField: "",
        confirmField: false,
      },
      images: [
        {
          image: colli,
          alt: "Colli berici",
        },
        {
          image: rotonda,
          alt: "Villa la rotonda",
        },
        {
          image: villa_nani,
          alt: "Villa Valmarana",
        },
        {
          image: teatro,
          alt: "Teatro Olimpico",
        },
        {
          image: basilica,
          alt: "Centro storico",
        },
        {
          image: san_bortolo,
          alt: "Ospedale San Bortolo",
        },
        {
          image: fiera,
          alt: "Fiera",
        },
      ],
      mainImageSrc: null,
      mainImageDescr: "",
      img_index: 0,
    };
  },
  methods: {
    handleClickHamburger: function (active) {
      this.mobile_menu_active = active;
    },

    handleSubmit() {
      this.submitted = true;
      if (
        this.form_email.nameField &&
        this.form_email.emailField &&
        this.form_email.titleField &&
        this.form_email.messageField
      ) {
        const name = this.form_email.nameField;
        const email = this.form_email.emailField;
        const title = this.form_email.titleField;
        const message = this.form_email.messageField;
        const confirm = this.form_email.confirmField;

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name, email, title, message, confirm }),
        };

        return fetch(`../contact_me.php`, requestOptions).then((response) => {
          console.log("Response: " + response);
        });
      }
    },

    menuClick: function (target, options) {
      const el = this.$el.getElementsByClassName(target)[0];
      if (el) {
        el.scrollIntoView(options);
      }

      this.menu_active = target;

      if (this.mobile_menu_active) {
        this.mobile_menu_active = false;
      }
    },

    imgRotation: function () {
      let self = this;
      setInterval(function () {
        if (self.img_index == self.images.length) {
          self.img_index = 0;
        }

        self.mainImageSrc = self.images[self.img_index].image;
        self.mainImageDescr = self.images[self.img_index].alt;
        self.img_index++;
      }, 5000);
    },
  },

  created() {
    this.imgRotation();
  },
};
</script>
<style lang="scss">
.main-image {
  position: relative;

  .descr_img {
    color: #d62828;
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 2rem;
    text-shadow: -1px -1px #fff;
  }
}

</style>
