<template>
  <div class="locale-changer" :class="this.mobile">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "LanguageSwitcher",
  props: ["mobile"],
  data() {
    return { langs: ["it", "en"] };
  },
};
</script>
<style lang="scss" scoped>
.locale-changer {
  select {
    margin: 0;
    padding: 0;
    border: 0;
    text-transform: uppercase;
    cursor: pointer;
    width: 6rem;
    height: 100%;
    background: none;
    font-size: 1.8rem;
    font-weight: 600;
    color: #404040;

    option {
      text-transform: uppercase;
    }
  }
}

@media (max-width: 41rem) {
  .locale-changer.mobile {
    select {
      text-align: center;
    }
  }
}
</style>