import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueI18n from 'vue-i18n';
import messages from './lang';
import './assets/milligram/milligram.sass';
import './assets/sass/main.scss';

Vue.config.productionTip = false


Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'it',
  fallbackLocale: 'it',
  messages
});

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
